<template>
  <v-container class="container--fluid grid-list-md">
    <v-row no-gutters>
      <v-col cols="12">
        <v-card class="mx-auto" outlined>
          <ListSkeleton v-if="listSkeleton" />
          <v-card-text class="text-h5 pa-0" v-else>
            <v-data-table
              :height=" journalEntryReportList && journalEntryReportList.length > 0 && showHeaders.length > 0 ? '475' : ''"
              :style="{ maxHeight: !journalEntryReportList.length ? '810px' : '' }"
              :dense="true"
              class="custom-table mb-0 ma-2 row-pointer"
              :headers="showHeaders"
              :items="journalEntryReportList"
              style="word-break: break-word"
              :search="search"
              item-class="py-4"
              fixed-header
              :items-per-page="limitData"
              :loading="isProgressCircular"
              :page.sync="page"
              @pagination="newPagination"
              @dblclick:row="doubleClick"
              @page-count="pageCount = $event"
              must-sort
            >
              <template v-slot:[`item.reference_one_Date`]="{ item }">
                <span>{{ item.reference_one_Date && (profileById.date_format ? formatDateDDMMYYYY(new Date(item.reference_one_Date).toISOString().substring(0,10)) :new Date(item.reference_one_Date).toISOString().substring(0,10)) }}</span>
              </template>
              <template v-slot:[`item.reference_two_Date`]="{ item }">
                <span>{{ item.reference_two_Date && (profileById.date_format ? formatDateDDMMYYYY(new Date(item.reference_two_Date).toISOString().substring(0,10)) :new Date(item.reference_two_Date).toISOString().substring(0,10)) }}</span>
              </template>
              <template v-slot:loading>
                <v-overlay>
                  <v-icon size="64" class="hourglass-icon" color="primary">mdi-timer-sand-paused</v-icon>
                </v-overlay>
              </template>
              <template v-slot:top>
                <v-card-title class="py-1 primary--text">
                  <v-icon color="primary">mdi-filter-cog-outline</v-icon>
                  <h6 class="ms-2 font-weight-regular">
                    {{ $t("order.filters") }}
                  </h6>
                  <v-spacer />
                  <HtmlToPdf class="me-2 mb-1" :disabled="journalEntryReportList.length" :model="journalEntryReportList" :payload="payload" type="JournalEntryReportPDF" />
                  <ExcelAndResetFilterCommon :data="journalEntryReportList.length" @downloadExcelFile="downloadExcelFile" @resetFilters="resetFilters"/>
                </v-card-title>
                <v-divider />
                <v-form v-model="valid" ref="form">
                  <v-card-text>
                    <v-row :dense="true">
                      <!-- <v-col cols="4">
                        <v-autocomplete
                          v-if="!type"
                          hide-details="auto"
                          v-model="selectedStatus"
                          :items="statusList"
                          item-text="name"
                          item-value="id"
                          flat
                          return-object
                          single-line
                          :label="$t('order.field.status')"
                        />
                      </v-col> -->
                      <v-col cols="4" class="text-end d-flex">
                        <v-menu
                          ref="startDateMenu"
                          v-model="startDateMenu"
                          :close-on-content-click="false"
                          :return-value.sync="orderDateFormat"
                          transition="scale-transition"
                          offset-y
                          :nudge-left="40"
                          min-width="auto"
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                              hide-details="auto"
                              class="me-2"
                              single-line
                              v-model="order_date"
                              :label="$t('order.from')"
                              :rules="profileById.date_format ? dateValid : dateValid2"
                              @blur="orderDateFormat = profileById.date_format ? parseDateYYYYMMDD(order_date) : order_date"
                              clearable
                              v-bind="attrs"
                              v-on="on"
                            ></v-text-field>
                          </template>
                          <v-date-picker v-model="orderDateFormat" @change="$refs.startDateMenu.save(orderDateFormat)">
                            <v-btn small class="primary" @click="$refs.startDateMenu.save(todayDate())">{{ $t("route.today") }}</v-btn>
                          </v-date-picker>
                        </v-menu>
                      </v-col>
                      <v-col cols="4">
                        <v-menu
                          ref="endDateMenu"
                          v-model="endDateMenu"
                          :close-on-content-click="false"
                          :return-value.sync="orderValideDateFormat"
                          transition="scale-transition"
                          offset-y
                          :nudge-left="160"
                          min-width="auto"
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                              hide-details="auto"
                              single-line
                              v-model="order_validity"
                              :label="$t('order.to')"
                              :rules="profileById.date_format ? dateValid : dateValid2"
                              @blur="orderValideDateFormat = profileById.date_format ? parseDateYYYYMMDD(order_validity) : order_validity"
                              clearable
                              v-bind="attrs"
                              v-on="on"
                            ></v-text-field>
                          </template>
                          <v-date-picker :min="orderDateFormat ? orderDateFormat : ''" v-model="orderValideDateFormat" @change="$refs.endDateMenu.save(orderValideDateFormat)">
                            <v-btn small class="primary" @click="$refs.endDateMenu.save(todayDate())" >{{ $t("route.today") }}</v-btn>
                          </v-date-picker>
                        </v-menu>
                      </v-col>
                    </v-row>
                    <v-row dense>
                      <v-col cols="12" class="text-center mt-3">
                        <ExecuteAndRestCommon @reset="reset" @paginate="paginate(false)" />
                        <v-dialog dense v-model="dialog" scrollable max-width="500px">
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn small color="primary" class="float-end" outlined v-bind="attrs" v-on="on">
                              <v-icon left> mdi-view-comfy </v-icon>
                              {{ $t("order.fieldView") }}
                            </v-btn>
                          </template>
                          <v-card>
                            <v-row class="align-center ma-0">
                              <v-col>
                                <v-card-title class="primary--text">
                                  <v-icon left color="primary">mdi-land-fields</v-icon>
                                  <h6 class="font-weight-regular">
                                    {{ $t("order.selectFields") }}
                                  </h6>
                                </v-card-title>
                              </v-col>
                              <v-col>
                                <v-btn x-small color="primary" class="float-end mx-3" @click="updateHeader">{{ $t("order.update") }}</v-btn>
                              </v-col>
                            </v-row>
                            <v-divider />
                            <v-card-text style="height: 300px">
                              <v-select
                                dense
                                class="mt-2"
                                hide-details="auto"
                                v-model="selectedHeaders"
                                :items="headers"
                                item-text="text"
                                item-value="id"
                                return-object
                                clearable
                                multiple
                                outlined
                                :menu-props="{ bottom: true, offsetY: true, maxHeight: 200, overflowY: true }"
                                :label="$t('order.selectFields')"
                              >
                                <template v-slot:prepend-item>
                                  <v-list-item @mousedown.prevent @click="toggle">
                                    <v-list-item-action>
                                      <v-checkbox
                                        dense
                                        v-model="selectFields"
                                      />
                                    </v-list-item-action>
                                    <v-list-item-content>
                                      <v-list-item-title>
                                        {{ $t("order.selectAll") }}
                                      </v-list-item-title>
                                    </v-list-item-content>
                                  </v-list-item>
                                  <v-divider class="mt-2" />
                                </template>
                              </v-select>
                            </v-card-text>
                          </v-card>
                        </v-dialog>
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-form>
                <v-card-title class="py-1 primary--text">
                  <v-row dense no-gutters>
                    <v-col cols="9" class="d-flex">
                      <v-icon color="primary">mdi-view-list-outline</v-icon>
                      <h6 class="ms-2 align-self-center font-weight-regular">
                        {{ $t("route.journalEntryReport") }}
                      </h6>
                    </v-col>
                    <v-col cols="3">
                      <v-text-field
                        ref="search"
                        :full-width="false"
                        :dense="true"
                        hide-details="auto"
                        :autofocus="type ? true : false"
                        v-model="search"
                        append-icon="mdi-magnify"
                        :label="$t('common.search')"
                        single-line
                      />
                    </v-col>
                  </v-row>
                </v-card-title>
                <v-divider />
              </template>
              <!-- <template v-slot:[`item.status`]="props">
                {{ statusList.filter((x) => x.id == props.item.status)[0].name }}
              </template> -->
              <template v-slot:[`footer.prepend`]>
                <v-row class="d-flex">
                  <v-col class="mx-auto" cols="5">
                    <span class="pl-3 pt-2"
                      >{{ $t("common.numberOfTotalRows") }}
                      {{ journalEntryReportList && journalEntryReportList.length && showHeaders.length > 0 ? numberOfRows : 0 }}</span>
                  </v-col>
                  <v-col class="mx-auto" cols="7">
                    <!-- <v-row dense>
                      <v-col cols="8" class="font-weight-bold" :class="locale === 'he' ? 'text-start' : 'text-end'">
                        <span class="pl-3 pt-2 font-weight-bold"
                          >{{ $t("order.total") }}: {{ journalEntryReportList && journalEntryReportList.totalPerReport ? numberWithCommas(journalEntryReportList.totalPerReport) : 0 }}
                        </span>
                      </v-col>
                    </v-row> -->
                  </v-col>
                </v-row>
              </template>
              <template v-slot:[`footer.page-text`]>
                <v-row class="d-flex">
                  <v-col class="mx-auto mr-16" cols="4">
                    <v-container class="w-100">
                      <v-pagination :total-visible="5" v-model="page" :length="journalEntryReportList ? pageCount : 0"></v-pagination>
                    </v-container>
                  </v-col>
                </v-row>
              </template>
              <template v-slot:no-data>
                <v-card-text class="text-caption text-center">{{ $t("common.noDataFound") }}</v-card-text>
              </template>
            </v-data-table>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";
import ListSkeleton from "@/components/skeleton/ListSkeleton";
import exportFromJSON from "export-from-json";
import { formatDateDDMMYYYY, parseDateYYYYMMDD, todayDate } from "@/utils";
import HtmlToPdf from "@/components/generatePDF/HtmlToPdf";
import ExcelAndResetFilterCommon from "@/components/ExcelAndResetFilterCommon";
import ExecuteAndRestCommon from "@/components/ExecuteAndRestCommon";
import { validationMixin } from '@/mixins/validationMixin';

export default {
  name: "JournalEntryReport",
  props: ["type"],
  components: {
    ListSkeleton,
    HtmlToPdf,
    ExcelAndResetFilterCommon,
    ExecuteAndRestCommon
  },
  mixins: [validationMixin],
  data() {
    return {
      listSkeleton: false,
      dialog: false,
      selectedHeaders: [],
      selectFields: false,
      search: this.$route.query.search || "",
      limitData: this.limit,
      limit: this.pageChange?.limit ? Number(this.pageChange?.limit) : 10,
      page: this.pageChange?.page ? Number(this.pageChange?.page) : 1,
      pagination: null,
      // selectedStatus: {
      //   id: this.$route.query.status === "all" ? null : this.$route.query.status === "closed" ? 1 : (this.$route.query.status === "open" && 0) || null,
      // },
      order_date: this.$route.query.startDate || new Date(new Date().setMonth(new Date().getMonth() - 6)).toISOString().substring(0, 10),
      order_validity: this.$route.query.endDate || new Date().toISOString().substring(0, 10),
      startDateMenu: false,
      endDateMenu: false,
      orderDateFormat: "",
      orderValideDateFormat: "",
      valid: false,
      numberOfRows: "",
      isProgressCircular: false,
    };
  },
  computed: {
    params() {
      return {
        search: this.search,
        // selectedStatus: this.selectedStatus?.id,
        order_date: this.order_date,
        order_validity: this.order_validity,
      };
    },
    payload() {
      return {
        // selectedStatus: this.selectedStatus?.name,
        order_date: this.order_date,
        order_validity: this.order_validity,
      };
    },
    selectAllHeaders() {
      return this.selectedHeaders.length === this.headers.length;
    },
    ...mapGetters({
      journalEntryReportList: "journalEntry/journalEntryReportList",
      locale: "locale",
      showHeaders: "journalEntryReport/showHeaders",
      accessRight: "accessRight",
      pageChange: "journalEntryReport/pageChange",
      profileById: "profile/profileById",
    }),
    // statusList() {
    //   return [
    //     { name: this.$t("order.field.all"), id: null },
    //     { name: this.$t("order.field.open"), id: 0 },
    //     { name: this.$t("order.field.finished"), id: 1 },
    //   ];
    // },
    computedDateFormatted() {
      return this.profileById.date_format
        ? this.formatDateDDMMYYYY(this.orderDateFormat)
        : this.orderDateFormat;
    },
    computedorderValideDateFormat() {
      return this.profileById.date_format
        ? this.formatDateDDMMYYYY(this.orderValideDateFormat)
        : this.orderValideDateFormat;
    },
    headers() {
      return [
        { text: this.$t("journalEntry.id"), value: "journal_entry_id", align: "start" },
        { text: this.$t("journalEntry.opposite"), value: "opposite" },
        { text: this.$t("journalEntry.referenceOne"), value: "reference_one" },
        { text: this.$t("journalEntry.referenceTwo"), value: "reference_two" },
        { text: this.$t("journalEntry.referenceThree"), value: "reference_one_Date" },
        { text: this.$t("journalEntry.valueDate"), value: "reference_two_Date" },
        { text: this.$t("journalEntry.details"), value: "document_details" },
        { text: this.$t("journalEntry.debit"), value: "debit_account" },
        { text: this.$t("journalEntry.credit"), value: "credit_account" },
        { text: this.$t("journalEntry.amount"), value: "amount" },
      ];
    },
  },
  created() {
    if (this.showHeaders.length == 0) {
      this.$store.commit("journalEntryReport/SHOW_HEADERS", this.headers);
    }
    this.page = this.pageChange.page;
    this.limit = this.pageChange.limit;
    this.limitData = this.limit;
  },
  beforeDestroy() {
    this.$store.commit("journalEntryReport/SHOW_PAGE_CHANGE", { page: this.pagination.page, limit: this.pagination.itemsPerPage });
  },
  watch: {
    pagination: {
      handler(newVal) {
        if (this.pagination) {
          this.limit = newVal.itemsPerPage ? newVal.itemsPerPage : 10;
        }
      },
      deep: true,
    },
    type: {
      handler() {
        this.search = "";
      },
    },
    orderDateFormat() {
      this.order_date = this.profileById.date_format
        ? this.formatDateDDMMYYYY(this.orderDateFormat)
        : this.orderDateFormat;
    },
    orderValideDateFormat() {
      this.order_validity = this.profileById.date_format
        ? this.formatDateDDMMYYYY(this.orderValideDateFormat)
        : this.orderValideDateFormat;
    },
    "$route.query": {
      handler(newVal, oldVal) {
        if (JSON.stringify(newVal) !== JSON.stringify(oldVal) ||!Object.keys(this.$route.query).length)
        this.search = this.$route.query.search || "";
        this.order_date = this.$route.query.startDate || new Date(new Date().setMonth(new Date().getMonth() - 6)).toISOString().substring(0, 10);
        this.order_validity = this.$route.query.endDate || new Date().toISOString().substring(0, 10)
        // this.selectedStatus = {
        //   id: this.$route.query.status === "all" ? null : this.$route.query.status === "closed" ? 1 : this.$route.query.status === "open" && 0,
        // };
      },
    },
    selectedHeaders() {
      if (this.selectedHeaders.length === this.headers.length) {
        this.selectFields = true;
      } else {
        this.selectFields = false;
      }
    },
    locale() {
      this.updateHeader();
    },
  },
  async mounted() {
    this.listSkeleton = true;
    this.selectedHeaders = this.showHeaders;
    this.page = this.pageChange.page;
    this.limit = this.pageChange.limit;
    this.limitData = this.limit;
    this.listSkeleton = false;
  },
  methods: {
    parseDateYYYYMMDD: parseDateYYYYMMDD,
    formatDateDDMMYYYY: formatDateDDMMYYYY,
    todayDate: todayDate,
    doubleClick(event, { item }) {
      const path = "journal-entry/journalEntryAction/" + item.journal_entry_id;
      this.accessRight.includes("edit") || this.accessRight.includes("show")
        ? window.open(path, '_blank')
        : "";
    },
    newPagination(val) {
      this.pagination = val;
      this.numberOfRows = val.itemsLength;
    },
    updateHeader() {
      this.$store.commit("journalEntryReport/SHOW_HEADERS", this.headers.filter((s) =>
        this.selectedHeaders.some((d) => d.value === s.value)
      ));
      this.selectedHeaders = this.showHeaders;
      this.dialog = false;
    },
    toggle() {
      this.$nextTick(() => {
        if (this.selectAllHeaders) {
          this.selectedHeaders = [];
        } else {
          this.selectedHeaders = this.headers.slice();
        }
      });
    },
    reset() {
      this.search = null;
      this.$store.commit("journalEntry/SET_JOURNAL_ENTRY_REPORT", []);
      this.page = 1;
      this.limit = 10;
      this.limitData = this.limit;
    },
    resetFilters() {
      this.orderDateFormat = "";
      this.orderValideDateFormat = "";
      this.$store.commit("journalEntry/SET_JOURNAL_ENTRY_REPORT_QUERY", {});
    },
    downloadExcelFile() {
      let self = this;
      let data = self.journalEntryReportList.map((o) =>
        Object.fromEntries(
          self.selectedHeaders.map((k) => 
            [
              k.text,
              k.value.includes(".")
                ? o[`${k.value.split(".")[0]}`] &&
                  Object.keys(o[`${k.value.split(".")[0]}`]).length > 0
                  ? o[`${k.value.split(".")[0]}`][`${k.value.split(".")[1]}`]
                  : o[`${k.value.split(".")[0]}`]
                : o[`${k.value}`],
            ]
          )
        )
      );
      data.forEach((el) => {
        for (let x in el) {
          if (!el[x]) {
            el[x] = "";
          }
        }
      });
      exportFromJSON({ data: JSON.parse(JSON.stringify(data)), fileName: "journalEntryReport", exportType: exportFromJSON.types.xls});
    },
    numberWithCommas(x) {
      return x ? parseFloat(x) .toFixed(2) .toString() .replace(/\B(?=(\d{3})+(?!\d))/g, ",") : (0).toFixed(2);
    },
    async paginate() {
      const { order_date, order_validity } = this.params;
      this.orderDateFormat = order_date ? this.parseDateYYYYMMDD(order_date) : "";
      this.orderValideDateFormat = order_validity ? this.parseDateYYYYMMDD(order_validity) : "";
      // make params for where_and
      let concateWhereAnd = "";
      let whereAndVal = "|";
      concateWhereAnd += order_date ? "from_reference_one_Date," : "";
      concateWhereAnd += order_validity ? "to_reference_one_Date," : "";
      whereAndVal += order_date
        ? this.profileById.date_format
          ? this.parseDateYYYYMMDD(order_date) + ","
          : order_date + ","
        : "";
      whereAndVal += order_validity
        ? this.profileById.date_format
          ? this.parseDateYYYYMMDD(order_validity) + ","
          : order_validity + ","
        : "";

      const payloadData = {
        type: "Journalentry",
        where_and:
          concateWhereAnd.slice(0, -1) && whereAndVal.slice(0, -1)
            ? concateWhereAnd.slice(0, -1) + whereAndVal.slice(0, -1)
            : undefined,
      };
      this.isProgressCircular = true;
      await this.$store.dispatch("journalEntry/JournalEntryReport", payloadData).then(() => {
        this.isProgressCircular = false;
        this.$refs.search.focus();
      });
    },
  },
};
</script>

<style scoped>
::v-deep .v-pagination__item {
  font-size: 12px;
}
::v-deep .custom-table ::-webkit-scrollbar {
  width: 12px;
  height: 12px;
}
</style>
